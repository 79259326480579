import SvgIcon from "@mui/material/SvgIcon";

export default function RetractionBull() {
  return (
    <SvgIcon viewBox="0 0 70 31" sx={{ width: "46px", height: "26px" }}>
      <svg
        width="80"
        height="31"
        viewBox="0 0 80 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(10, 0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.4335 29.9875C48.2656 29.9875 54.6092 23.6598 54.6092 15.8609C54.6092 8.06211 48.2656 1.73438 40.4335 1.73438C32.6014 1.73438 26.2578 8.06211 26.2578 15.8609C26.2578 23.6598 32.6014 29.9875 40.4335 29.9875ZM40.4335 30.9875C48.8148 30.9875 55.6092 24.2151 55.6092 15.8609C55.6092 7.50677 48.8148 0.734375 40.4335 0.734375C32.0522 0.734375 25.2578 7.50677 25.2578 15.8609C25.2578 24.2151 32.0522 30.9875 40.4335 30.9875Z"
            fill="#212121"
          />
          <path
            d="M48.3036 12.238C48.2231 12.0441 48.069 11.8901 47.8752 11.8096C47.7798 11.7689 47.6773 11.7473 47.5737 11.7461H43.6066C43.3961 11.7461 43.1943 11.8297 43.0455 11.9785C42.8967 12.1273 42.8132 12.3291 42.8132 12.5395C42.8132 12.7499 42.8967 12.9517 43.0455 13.1005C43.1943 13.2493 43.3961 13.3329 43.6066 13.3329H45.6615L41.2263 17.7681L38.616 15.1498C38.5422 15.0755 38.4545 15.0165 38.3578 14.9762C38.2611 14.9359 38.1574 14.9152 38.0527 14.9152C37.9479 14.9152 37.8442 14.9359 37.7475 14.9762C37.6508 15.0165 37.5631 15.0755 37.4893 15.1498L32.7288 19.9103C32.6545 19.9841 32.5954 20.0719 32.5552 20.1685C32.5149 20.2652 32.4941 20.3689 32.4941 20.4737C32.4941 20.5784 32.5149 20.6821 32.5552 20.7788C32.5954 20.8755 32.6545 20.9632 32.7288 21.037C32.8026 21.1114 32.8903 21.1704 32.987 21.2107C33.0837 21.251 33.1874 21.2717 33.2922 21.2717C33.3969 21.2717 33.5006 21.251 33.5973 21.2107C33.694 21.1704 33.7817 21.1114 33.8555 21.037L38.0527 16.8319L40.663 19.4502C40.7368 19.5245 40.8245 19.5836 40.9212 19.6238C41.0179 19.6641 41.1216 19.6849 41.2263 19.6849C41.3311 19.6849 41.4348 19.6641 41.5315 19.6238C41.6281 19.5836 41.7159 19.5245 41.7896 19.4502L46.7802 14.4516V16.5066C46.7802 16.717 46.8638 16.9188 47.0126 17.0676C47.1614 17.2164 47.3632 17.3 47.5737 17.3C47.7841 17.3 47.9859 17.2164 48.1347 17.0676C48.2835 16.9188 48.3671 16.717 48.3671 16.5066V12.5395C48.3658 12.4358 48.3443 12.3334 48.3036 12.238Z"
            fill="white"
          />
        </g>
        <circle cx="15.7852" cy="15.8613" r="15.127" fill="#10171C" />
        <path
          d="M15.7845 22.569C13.932 22.569 12.255 21.8182 11.041 20.6042L15.7845 15.8607V9.15234C19.4894 9.15234 22.4928 12.1558 22.4928 15.8607C22.4928 19.5656 19.4894 22.569 15.7845 22.569Z"
          fill="#01DB97"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3275 15.862C26.3275 21.684 21.6078 26.4036 15.7858 26.4036C9.96381 26.4036 5.24414 21.684 5.24414 15.862C5.24414 10.04 9.96381 5.32031 15.7858 5.32031C21.6078 5.32031 26.3275 10.04 26.3275 15.862ZM24.4108 15.862C24.4108 20.6255 20.5493 24.487 15.7858 24.487C11.0224 24.487 7.16081 20.6255 7.16081 15.862C7.16081 11.0985 11.0224 7.23698 15.7858 7.23698C20.5493 7.23698 24.4108 11.0985 24.4108 15.862Z"
          fill="#01DB97"
        />
      </svg>
    </SvgIcon>
  );
}
