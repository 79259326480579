import { useMediaQuery, useTheme } from "@mui/material";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useNavigate } from "react-router";
import { apiGet, apiPost } from "src/services/apiService";
import useUser from "src/swr/use-user";
import { RequestPix } from "src/types/deposit";
import formatCurrency from "src/utils/formatCurrency";
import { notifyError, notifySuccess } from "src/utils/toast";
import { useLocalStorage } from "usehooks-ts";
import { Candle, Ticker, VolumeBook } from "../types/candle";
import { Order } from "../types/order";
import LayoutContext from "./LayoutContext";
import TradingViewContext from "./TradingViewContext";

import orderLoseSound from "src/assets/audio/notifications/bottle-205353.mp3";
import orderWinSound from "src/assets/audio/notifications/new-notification-7-210334.mp3";
import { getBrokerNowTime } from "src/components/TVChartContainer/datafeed";
import useQuery from "src/hooks/useQuery";
import useParameters from "src/swr/use-parameters";
import { User } from "src/types/user";
import { getUpdatedTimestamp } from "src/utils/getUpdatedTimestamp";
import { isValidOrder } from "src/utils/isValidOrder";
import useSound from "use-sound";
import SettingsContext from "./SettingsContext";

export type IOperationMode = "RETRACTION_ENDTIME" | "OPTION";

interface State {
  user: User;
  operationMode: IOperationMode;
  selectedCandle: string | "M1"; // Default timeframe
  selectedDatatime: number;
  orderValue: number;
  candles: Candle[];
  userBalance: number;
  userLiveOperations: { [key: string]: Order };
  userOrders: Order[];
  chartOrders: any[];
  volumeBook: VolumeBook;
  tickerBook: { candleTimeFrame: string; ticker: Ticker }[];
  serverTime: number;
  payout: number;
}

interface ApiProviderProps {
  children: ReactNode;
}

type ChangeOperationModeAction = {
  type: "CHANGE_OPERATION_MODE";
  payload: {
    operationMode: IOperationMode;
  };
};

type ChangeTimeframeAction = {
  type: "CHANGE_TIMEFRAME";
  payload: {
    selectedCandle: string;
  };
};

type ChangeTimeAction = {
  type: "CHANGE_DATATIME";
  payload: {
    selectedDatatime: any;
  };
};

type UpdateCandlesAction = {
  type: "UPDATE_CANDLES";
  payload: {
    candles: Candle[];
  };
};

type UpdateUserBalanceAction = {
  type: "UPDATE_USER_BALANCE";
  payload: {
    userBalance: number;
  };
};

type UpdateLiveOperationsAction = {
  type: "UPDATE_USER_LIVE_OPERATIONS";
  payload: {
    userLiveOperations: { [key: string]: Order };
  };
};

type RemoveLiveOperationsOrder = {
  type: "REMOVE_LIVE_OPERATIONS_ORDER";
  payload: {
    orderId: string;
  };
};

type UpdateUserOrdersAction = {
  type: "UPDATE_USER_ORDERS";
  payload: {
    userOrders: Order[];
  };
};

type UpdateChartOrdersAction = {
  type: "UPDATE_CHART_ORDERS";
  payload: {
    chartOrders: any[];
  };
};

type UpdateTickerBookAction = {
  type: "UPDATE_TICKERBOOK_ACTION";
  payload: {
    candleTimeFrame: string;
    ticker: Ticker;
  };
};

type UpdateServerTimeAction = {
  type: "UPDATE_SERVER_TIME";
  payload: {
    serverTime: number;
  };
};

type UpdateUserBookAction = {
  type: "UPDATE_USER_BOOK";
};

type SetOrderValueAction = {
  type: "SET_ORDER_VALUE";
  payload: {
    orderValue: number;
  };
};

type SetFeeAction = {
  type: "SET_PAYOUT";
  payload: {
    payout: number;
  };
};

const initialState: State = {
  user: null,
  operationMode:
    (JSON.parse(
      localStorage.getItem("defaultOperationMode")
    ) as IOperationMode) || "OPTION",
  selectedCandle: "M1",
  selectedDatatime: getUpdatedTimestamp(new Date(getBrokerNowTime()), 1),
  orderValue: 1,
  candles: [],
  userBalance: 0,
  userLiveOperations: {},
  userOrders: [],
  chartOrders: [],
  volumeBook: null,
  tickerBook: null,
  serverTime: null,
  payout: 90,
  // tickerBook: [],
  // ticker: []
};

type Action =
  | ChangeOperationModeAction
  | ChangeTimeframeAction
  | ChangeTimeAction
  | UpdateCandlesAction
  | UpdateUserBalanceAction
  | UpdateTickerBookAction
  | UpdateUserBookAction
  | UpdateServerTimeAction
  | UpdateLiveOperationsAction
  | RemoveLiveOperationsOrder
  | UpdateUserOrdersAction
  | UpdateChartOrdersAction
  | SetOrderValueAction
  | SetFeeAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
  CHANGE_OPERATION_MODE: (
    state: State,
    action: ChangeOperationModeAction
  ): State => {
    const { operationMode } = action.payload;
    return {
      ...state,
      operationMode,
    };
  },

  CHANGE_TIMEFRAME: (state: State, action: ChangeTimeframeAction): State => {
    const { selectedCandle } = action.payload;
    return {
      ...state,
      selectedCandle,
    };
  },

  CHANGE_DATATIME: (state: State, action: ChangeTimeAction): State => {
    const { selectedDatatime } = action.payload;
    return {
      ...state,
      selectedDatatime,
    };
  },

  UPDATE_CANDLES: (state: State, action: UpdateCandlesAction): State => {
    const { candles } = action.payload;
    return {
      ...state,
      candles,
    };
  },

  UPDATE_USER_BALANCE: (
    state: State,
    action: UpdateUserBalanceAction
  ): State => {
    const { userBalance } = action.payload;
    return {
      ...state,
      userBalance,
    };
  },

  UPDATE_USER_ORDERS: (state: State, action: UpdateUserOrdersAction): State => {
    const { userOrders } = action.payload;
    return {
      ...state,
      userOrders,
    };
  },

  UPDATE_USER_LIVE_OPERATIONS: (
    state: State,
    action: UpdateLiveOperationsAction
  ): State => {
    const { userLiveOperations } = action.payload;

    console.log("userLiveOperations ->", userLiveOperations);
    return {
      ...state,
      userLiveOperations,
    };
  },

  REMOVE_LIVE_OPERATIONS_ORDER: (
    state: State,
    action: RemoveLiveOperationsOrder
  ): State => {
    const { orderId } = action.payload;
    const newOrders = { ...state.userLiveOperations };
    delete newOrders[orderId];
    return {
      ...state,
      userLiveOperations: newOrders,
    };
  },

  UPDATE_CHART_ORDERS: (
    state: State,
    action: UpdateChartOrdersAction
  ): State => {
    const { chartOrders } = action.payload;
    return {
      ...state,
      chartOrders,
    };
  },

  UPDATE_TICKERBOOK_ACTION: (
    state: State,
    action: UpdateTickerBookAction
  ): State => {
    const { candleTimeFrame, ticker } = action.payload;
    let tickerBookRemovingCurrentTimeFrame: any[] = state.tickerBook?.filter(
      (it) => it.candleTimeFrame !== candleTimeFrame
    );
    if (!tickerBookRemovingCurrentTimeFrame) {
      tickerBookRemovingCurrentTimeFrame = [];
    }
    return {
      ...state,
      tickerBook: [
        ...tickerBookRemovingCurrentTimeFrame,
        { candleTimeFrame, ticker },
      ],
    };
  },

  UPDATE_SERVER_TIME: (
    state: State,
    action: UpdateServerTimeAction
  ): State => ({
    ...state,
    serverTime: action.payload.serverTime,
  }),

  SET_ORDER_VALUE: (state: State, action: SetOrderValueAction): State => ({
    ...state,
    orderValue: action.payload.orderValue,
  }),
  SET_PAYOUT: (state: State, action: SetFeeAction): State => ({
    ...state,
    payout: action.payload.payout,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const extractCandleTimeFrameFromPayload = (data) =>
  data.payload.candleTimeFrame;

interface ApiContextValue extends State {
  updateTimeframe: (timeframe: string) => Promise<void>;
  updateDatatime: (timestamp: any) => Promise<void>;
  updateCandles: (candles: Candle[]) => Promise<void>;
  updateUserBalance: (balance: number) => Promise<void>;
  updateUserOrders: (orders: Order[]) => Promise<void>;
  updateUserLiveOperations: (
    orders: Order[],
    addOrdersToChart?: boolean
  ) => Promise<void>;
  addUserLiveOperatons: (order: Order) => Promise<void>;
  updateTickerBook: (candleTimeFrame: string, ticker: Ticker) => Promise<void>;
  getSelectedTickerBook: () => Ticker;
  updateServerTime: (serverTime: number) => void;
  updateOperationMode: (operationModeName: IOperationMode) => void;
  handleBalanceEvent: (data: any) => void;
  handleUserOrdersEvent: (data: any, addOrders?: boolean) => void;
  handleTicker: (data: any) => void;
  handleCandleClose: (data: any) => void;
  handleWinLose: (data: any) => void;
  handleRefundedOrder: (data: any) => void;
  handleServerTime: (data: any) => void;
  handleRequestPix: (requestPix: RequestPix) => Promise<void>;
  setOrderValue: (orderValue: number) => void;
  handleSingleUserOrder: (data: any) => void;
  handleRemoveLiveOperationsOrder: (orderId: string) => void;
  updatePayout: (payout: number) => void;
}

const ApiContext = createContext<ApiContextValue>({
  ...initialState,
  updateTimeframe: () => Promise.resolve(),
  updateDatatime: () => Promise.resolve(),
  updateCandles: () => Promise.resolve(),
  updateUserBalance: () => Promise.resolve(),
  updateUserOrders: () => Promise.resolve(),
  updateUserLiveOperations: () => Promise.resolve(),
  addUserLiveOperatons: () => Promise.resolve(),
  updateTickerBook: () => Promise.resolve(),
  // updateUserBook: () => Promise.resolve(),
  updateOperationMode: () => null,
  getSelectedTickerBook: () => null,
  updateServerTime: () => null,
  handleBalanceEvent: () => null,
  handleUserOrdersEvent: () => null,
  handleTicker: () => null,
  handleCandleClose: () => null,
  handleWinLose: () => null,
  handleRefundedOrder: () => null,
  handleServerTime: () => null,
  handleRequestPix: () => null,
  setOrderValue: () => null,
  handleSingleUserOrder: () => null,
  handleRemoveLiveOperationsOrder: () => null,
  updatePayout: () => null,
});

export const ApiProvider: FC<ApiProviderProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  const query = useQuery();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [playOrderWin] = useSound(orderWinSound);
  const [playOrderLose] = useSound(orderLoseSound);

  const novo = query.get("novo");

  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedSymbol, addChartOrders, winLoseChartOrder } =
    useContext(TradingViewContext);
  const { setModalRequireDocumentValidate, setModalOutsideMarket } =
    useContext(LayoutContext);
  const { settings } = useContext(SettingsContext);
  const [defaultOperationMode, setDefaultOperationMode] = useLocalStorage(
    "defaultOperationMode",
    JSON.parse(localStorage.getItem("defaultOperationMode")) || "OPTION"
  );
  const [defaultCandleTimeFrame, setDefaultCandleTimeFrame] = useLocalStorage(
    "defaultCandleTimeFrame",
    "M1"
  );
  const [defaultSymbol, setDefaultSymbol] = useLocalStorage(
    "defaultSymbol",
    "IDXUSDT"
  );
  const { parameters, loading } = useParameters();
  const { user } = useUser();

  useEffect(() => {
    updateTimeframe(defaultCandleTimeFrame);
    updateOperationMode(defaultOperationMode as IOperationMode);
  }, []);

  const updateOperationMode = (operationModeName: IOperationMode): void => {
    setDefaultOperationMode(operationModeName);
    dispatch({
      type: "CHANGE_OPERATION_MODE",
      payload: {
        operationMode: operationModeName,
      },
    });
  };

  const updateTimeframe = async (timeframe: string): Promise<void> => {
    setDefaultCandleTimeFrame(timeframe);
    dispatch({
      type: "CHANGE_TIMEFRAME",
      payload: {
        selectedCandle: timeframe,
      },
    });
  };

  const updateDatatime = async (timestamp: number): Promise<void> => {
    dispatch({
      type: "CHANGE_DATATIME",
      payload: {
        selectedDatatime: timestamp,
      },
    });
  };

  const updateCandles = async (candles: Candle[]): Promise<void> => {
    dispatch({
      type: "UPDATE_CANDLES",
      payload: {
        candles,
      },
    });
  };

  const updateUserBalance = async (balance: number): Promise<void> => {
    dispatch({
      type: "UPDATE_USER_BALANCE",
      payload: {
        userBalance: balance,
      },
    });
  };

  const updateTickerBook = async (
    candleTimeFrame: string,
    ticker: Ticker
  ): Promise<void> => {
    dispatch({
      type: "UPDATE_TICKERBOOK_ACTION",
      payload: {
        candleTimeFrame,
        ticker,
      },
    });
  };

  const updateServerTime = (serverTime) => {
    dispatch({
      type: "UPDATE_SERVER_TIME",
      payload: {
        serverTime,
      },
    });
  };

  const updateUserBook = async (): Promise<void> => {
    dispatch({
      type: "UPDATE_USER_BOOK",
    });
  };

  const getSelectedTickerBook = (): Ticker => {
    const bla = state.tickerBook?.filter(
      (it) => it.candleTimeFrame === state.selectedCandle
    );
    if (bla !== undefined && bla.length > 0) {
      return bla[0].ticker;
    }
    return {
      volume: {
        green: 0,
        red: 0,
      },
      book: {
        green: 0,
        red: 0,
        volume: 0,
      },
    };
  };

  const handleBalanceEvent = (data) => {
    const balanceEvent = "user_balance";
    if (data.event === balanceEvent) {
      updateUserBalance(data.payload.usdt);
    }

    const ordersEvent = "user_orders";
    if (data.event === ordersEvent) {
      updateUserBook();
    }
  };

  let logTimeoutId;

  const handleUserOrdersEvent = async (data, addOrders = true) => {
    const ordersEvent = "user_orders";
    fetchOrders();
    if (data.event === ordersEvent && addOrders) {
      // Filtrar ordens abertas que já deveriam ter sido finalizadas
      const invalidOpenOrders = data.payload.open.filter(
        (order) => !isValidOrder(order)
      );

      // Filtrar ordens pendentes que já deveriam ter sido finalizadas
      const invalidPendingOrders = data.payload.pending.filter(
        (order) => !isValidOrder(order)
      );

      // Se houver ordens inválidas, configurar um timeout para registrar o log
      if (invalidOpenOrders.length > 0 || invalidPendingOrders.length > 0) {
        logTimeoutId = setTimeout(() => {
          console.warn(
            "Received invalid orders that should have been finalized:",
            {
              invalidOpenOrders,
              invalidPendingOrders,
            }
          );
        }, 5000); // Aguardar 5 segundos antes de registrar o log
      }

      // Filtrar ordens válidas abertas
      const validOpenOrders = data.payload.open.filter((order) =>
        isValidOrder(order)
      );

      console.log("validOpenOrders", validOpenOrders);

      // Filtrar ordens válidas pendentes
      const validPendingOrders = data.payload.pending.filter((order) =>
        isValidOrder(order)
      );

      // Se recebermos uma atualização correta, cancelar o timeout
      if (
        (validOpenOrders.length > 0 || validPendingOrders.length > 0) &&
        logTimeoutId
      ) {
        clearTimeout(logTimeoutId);
        logTimeoutId = null;
      }

      const ordersPendingAndOpen = orderBy(
        validPendingOrders.concat(validOpenOrders),
        [(item) => new Date(item.createdAt)],
        ["asc"]
      );

      updateUserLiveOperations(ordersPendingAndOpen);
    }
  };

  const handleSingleUserOrder = (data) => {
    const order = data.payload;
    if (["WIN", "LOSE"].includes(order.status)) return;

    const orders = { ...state.userLiveOperations };

    if (order.status === "REFUNDED") {
      delete orders[order.id];
    } else {
      orders[order.id] = order;
    }

    const ordersOrdered = orderBy(
      Object.values(orders),
      [(item) => new Date(item.createdAt)],
      ["asc"]
    );

    updateUserLiveOperations(ordersOrdered);
  };

  const fetchOrders = async () => {
    if (!parameters) {
      return;
    }
    try {
      const candleTimeFrames = JSON.parse(parameters.CANDLE_TIME_FRAMES.value);
      const timeFrames = candleTimeFrames.map((item) => item.value).join(",");

      const response = await apiGet<Order[]>(
        `/orders?candleTimeFrames=${timeFrames}&symbols=${selectedSymbol}&statuses=PENDING,OPEN&page=0&size=10`
      );

      const newOrders = response.reduce((obj, item) => {
        // Atualiza somente se a ordem for nova ou com status diferente
        if (
          !state.userLiveOperations[item.id] ||
          state.userLiveOperations[item.id].status !== item.status
        ) {
          obj[item.id] = item;
        } else {
          obj[item.id] = state.userLiveOperations[item.id];
        }
        return obj;
      }, {});

      dispatch({
        type: "UPDATE_USER_LIVE_OPERATIONS",
        payload: {
          userLiveOperations: newOrders,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Atualização otimizada das operações ao vivo
  const updateUserLiveOperations = async (
    orders: Order[],
    addOrdersToChart = true
  ): Promise<void> => {
    let ordersForTheChart = orders.filter((o) => {
      if (o.binaryOrderType === "OPTION") {
        return (
          o.symbol === defaultSymbol &&
          o.candleTimeFrame === defaultCandleTimeFrame
        );
      }

      return o.symbol === defaultSymbol;
    });

    if (addOrdersToChart) {
      const validOrders = ordersForTheChart.filter((order) =>
        isValidOrder(order)
      );

      addChartOrders(validOrders, defaultCandleTimeFrame);
    }

    // const newOrders = activeOrdesBySymbol.reduce((obj, item) => {
    //   // Atualiza somente se a ordem for nova ou com status diferente
    //   if (
    //     !state.userLiveOperations[item.id] ||
    //     state.userLiveOperations[item.id].status !== item.status
    //   ) {
    //     obj[item.id] = item;
    //   } else {
    //     obj[item.id] = state.userLiveOperations[item.id];
    //   }
    //   return obj;
    // }, {});

    // console.log("newOrders", newOrders);

    // // Batching das atualizações no estado
    // dispatch({
    //   type: "UPDATE_USER_LIVE_OPERATIONS",
    //   payload: {
    //     userLiveOperations: newOrders,
    //   },
    // });
  };

  const addUserLiveOperatons = async (order: Order): Promise<void> => {
    const orders = state.userLiveOperations;
    orders[order.id] = order;

    const ordersOrdered = orderBy(
      Object.values(orders),
      [(item) => new Date(item.createdAt)],
      ["asc"]
    );

    updateUserLiveOperations(ordersOrdered);
  };

  const handleRemoveLiveOperationsOrder = (orderId: string) => {
    dispatch({
      type: "REMOVE_LIVE_OPERATIONS_ORDER",
      payload: {
        orderId,
      },
    });
  };

  const updateUserOrders = async (orders: Order[]): Promise<void> => {
    console.log("updateUserOrders", orders);
    dispatch({
      type: "UPDATE_USER_ORDERS",
      payload: {
        userOrders: orders,
      },
    });
  };

  const handleTicker = (data) => {
    if (
      data.event === "ticker" &&
      state.selectedCandle === extractCandleTimeFrameFromPayload(data)
    ) {
      const tickerData = data.payload;
      updateTickerBook(state.selectedCandle, {
        volume: {
          green: tickerData.volume.green24,
          red: tickerData.volume.red24,
          volume: tickerData.volume.volume24,
        },
        book: {
          green: tickerData.book.green,
          red: tickerData.book.red,
        },
      });
    }
  };

  const handleCandleClose = (data) => {
    if (data.event.startsWith("candle_close")) {
      const candleNumber = extractCandleTimeFrameFromPayload(data);

      if (candleNumber === state.selectedCandle) {
        if (state.tickerBook) {
          const tickerbook = state.tickerBook.filter(
            (it) => it.candleTimeFrame === candleNumber
          )?.[0];

          updateTickerBook(state.selectedCandle, {
            volume: {
              green: tickerbook?.ticker.volume.green || 0,
              red: tickerbook?.ticker.volume.red || 0,
              volume: tickerbook?.ticker.volume.volume || 0,
            },
            book: {
              green: 0,
              red: 0,
            },
          });
        }
      }
    }
  };

  const handleWinLose = (data) => {
    const loseOrderEvent = `lose_order`;
    const winOrderEvent = `win_order`;

    const removeOrderFromLiveOperations = (orderIds) => {
      const newOrders = Object.keys(state.userLiveOperations)
        .filter((key) => !orderIds.includes(key))
        .reduce((result, current) => {
          result[current] = state.userLiveOperations[current];
          return result;
        }, {});

      console.log("newOrders", newOrders);

      dispatch({
        type: "UPDATE_USER_LIVE_OPERATIONS",
        payload: {
          userLiveOperations: newOrders,
        },
      });
    };

    // const orderId = `order-${data.payload.orderDirection}-${data.payload.candleStartTime}-OPEN`;
    // removeOrderOpenedById(orderId);

    if (data.event === loseOrderEvent) {
      const result = `Resultado: Perda - ${formatCurrency(data.payload.value)}`;
      winLoseChartOrder(data.payload, "lose");
      notifyError(result);

      if (settings.isSoundOn) {
        playOrderLose();
      }

      // Remover as ordens do userLiveOperations
      removeOrderFromLiveOperations(data.payload.ordersIds);
    }

    if (data.event === winOrderEvent) {
      const result = `Resultado: Lucro + ${formatCurrency(data.payload.value)}`;
      winLoseChartOrder(data.payload, "win");
      notifySuccess(result);

      if (settings.isSoundOn) {
        playOrderWin();
      }

      // Remover as ordens do userLiveOperations
      removeOrderFromLiveOperations(data.payload.ordersIds);
    }
  };

  const handleRefundedOrder = (data) => {
    const refundedOrderEvent = `refunded-order-${user.email}`;
    if (data.event === refundedOrderEvent) {
      //
    }
  };

  const handleServerTime = (data) => {
    const event = "server_time";
    if (data.event === event) {
      updateServerTime(data.payload.serverNowDate);
    }
  };

  const handleRequestPix = async (requestPix: RequestPix) => {
    try {
      const cpf = requestPix.cpf.replace(/\D/g, "");

      const amount = requestPix.brl.toString();

      delete requestPix.usdt;
      delete requestPix.brl;

      const data = await apiPost("bank/deposits/nox/pix", {
        ...requestPix,
        amount,
        cpf,
      });

      navigate(
        `/dashboard/profile?tab=deposit&depositId=${data.id}&gatewayTransactionType=pix`
      );
    } catch (error) {
      console.log("error", error);
      if (error === "UserMustBeVerifiedBeforeAskingForDepositException") {
        setModalRequireDocumentValidate(true);
      }
    }
  };

  const setOrderValue = (orderValue: number) => {
    dispatch({
      type: "SET_ORDER_VALUE",
      payload: {
        orderValue,
      },
    });
  };

  const updatePayout = (payout: number) => {
    dispatch({
      type: "SET_PAYOUT",
      payload: {
        payout,
      },
    });
  };

  return (
    <ApiContext.Provider
      value={{
        ...state,
        user,
        updateOperationMode,
        updateTimeframe,
        updateDatatime,
        updateCandles,
        updateUserBalance,
        updateTickerBook,
        updateUserOrders,
        updateUserLiveOperations,
        addUserLiveOperatons,
        getSelectedTickerBook,
        updateServerTime,
        handleBalanceEvent,
        handleUserOrdersEvent,
        handleTicker,
        handleCandleClose,
        handleWinLose,
        handleRefundedOrder,
        handleServerTime,
        handleRequestPix,
        setOrderValue,
        handleSingleUserOrder,
        handleRemoveLiveOperationsOrder,
        updatePayout,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiContext;
