import {
  Accordion,
  AccordionSummary,
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OptionBull from "src/components/icons/OptionBull";
import OptionSell from "src/components/icons/OptionSell";
import RetractionBull from "src/components/icons/RetractionBull";
import RetractionSell from "src/components/icons/RetractionSell";
import { getLastBar } from "src/components/TVChartContainer/streaming";
import { SYMBOL_IMAGES } from "src/constants";
import { useCountdownContext } from "src/contexts/CountdownContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useApiData from "src/hooks/useApiData";
import useAvailableSymbols from "src/swr/use-available-symbols";
import useParameters from "src/swr/use-parameters";
import labelsColors from "src/theme/labelsColors";
import { getResulteOnLive } from "src/utils/getResultOnLine";
import { scrollStyle } from ".";

const ACTIVE_FRAME_SECONDS = {
  M1: 60,
  M5: 300,
  M15: 900,
};

export default function LiveOrders() {
  const { t } = useTranslation("dashboard");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    "panel1"
  );

  const { parameters, loading } = useParameters();
  const { selectedSymbol } = useContext(TradingViewContext);
  const { userLiveOperations } = useApiData();
  const lastDailyBar = getLastBar(selectedSymbol, "OperationsHistoricTab");
  const { selectedSymbolData } = useAvailableSymbols();
  const { count, count1m } = useCountdownContext();

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <Box sx={scrollStyle}>
      {Object.keys(userLiveOperations).length === 0 ? (
        <Typography
          color="#919eab"
          variant="body1"
          py={2}
          px={1.4}
          textAlign={isMobile ? "center" : "left"}
        >
          {t("no_operations")}
        </Typography>
      ) : (
        orderBy(
          Object.values(userLiveOperations),
          [(item) => new Date(item.createdAt)],
          ["desc"]
        ).map((item) => (
          <OrderAccordion
            key={item.id}
            item={item}
            expandedAccordion={expandedAccordion}
            handleChangeAccordion={handleChangeAccordion}
            loading={loading}
            lastDailyBar={lastDailyBar}
          />
        ))
      )}
    </Box>
  );
}

interface OrderAccordionProps {
  item: any;
  expandedAccordion: string | false;
  handleChangeAccordion: (
    panel: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  loading: boolean;
  lastDailyBar: any;
}

const OrderAccordion: FC<OrderAccordionProps> = ({
  item,
  expandedAccordion,
  handleChangeAccordion,
  loading,
  lastDailyBar,
}) => {
  const { count, count1m } = useCountdownContext();

  const isNewOptionsTab = item.binaryOrderType === "OPTION";
  const countValue = isNewOptionsTab ? count : count1m;

  const payout = useMemo(() => {
    return 100 - item.feeRate;
  }, [item]);

  return (
    <Accordion
      expanded={expandedAccordion === item.id}
      onChange={handleChangeAccordion(item.id)}
      elevation={0}
      sx={{
        "& .MuiAccordionSummary-content": {
          display: "flex",
          justifyContent: "space-between",
        },
        "& .MuiAccordionSummary-root": {
          paddingLeft: 0,
          bgcolor: "transparent",
          background: "transparent",
          height: "64px",
          border: `0`,
          borderBottom: `1px solid #0d1b24`,
          "&:last-of-type": {
            border: 0,
          },
          position: "relative",
        },
        background: "transparent",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        sx={{
          pointerEvents: "none",
          "& .MuiAccordionSummary-expandIconWrapper": {
            display: "none",
          },
          "& .info": {
            display: "flex",
            alignItems: "center",
          },
          "& .profit": {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "0.25rem",
          },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="info">
              {/* @ts-ignore */}
              <img width={40} src={SYMBOL_IMAGES[item.symbol].image} alt="" />

              <div>
                <Typography color={"#EEE"}>{item.symbol}</Typography>
                <Typography variant="body1" color={"#797979"}>
                  {format(new Date(item.candleStartTime), "HH:mm")} /{" "}
                  {item.binaryOrderType === "OPTION"
                    ? item.candleTimeFrame
                    : format(new Date(item.candleEndTime), "HH:mm")}
                </Typography>
              </div>
            </div>
            <div className="profit">
              {item.direction.toUpperCase() === "BULL" ? (
                item.binaryOrderType === "OPTION" ? (
                  <OptionBull />
                ) : (
                  <RetractionBull />
                )
              ) : item.binaryOrderType === "OPTION" ? (
                <OptionSell />
              ) : (
                <RetractionSell />
              )}
              <>
                {loading ? (
                  <Skeleton />
                ) : (
                  <OrderResult
                    order={item}
                    payout={payout}
                    lastDailyBar={lastDailyBar}
                  />
                )}
              </>
            </div>
          </Box>
          <ProgressBar
            candleStartTime={item.candleStartTime}
            candleEndTime={item.candleEndTime}
            status={item.status}
          />
        </Box>
      </AccordionSummary>
    </Accordion>
  );
};

interface OrderResultProps {
  order: any;
  payout: number;
  lastDailyBar: any;
}

const OrderResult: FC<OrderResultProps> = ({ order, payout, lastDailyBar }) => {
  const { t } = useTranslation("dashboard");

  const [result, setResult] = useState({
    color: "",
    simbol: "",
    result: "",
    status: "",
  });

  useEffect(() => {
    const updatedResult = getResulteOnLive({
      order,
      payout,
      lastDailyBar,
    });
    setResult(updatedResult);
  }, [order, payout, lastDailyBar]);

  return (
    <Box>
      <Typography color={result.color} variant="subtitle1">
        {`${result.simbol}${result.result}`}
      </Typography>
      <Typography
        variant="body1"
        {...(result.status === "OPEN"
          ? { color: "#FFF" }
          : { color: labelsColors.PENDING.color })}
      >
        {t(result.status.toLocaleLowerCase())}
      </Typography>
    </Box>
  );
};
interface ProgressBarProps {
  candleStartTime: string;
  candleEndTime: string;
  status: string;
}

const ProgressBar: FC<ProgressBarProps> = ({
  candleStartTime,
  candleEndTime,
  status,
}) => {
  const [progressPercentage, setProgressPercentage] = useState(100);

  useEffect(() => {
    if (status === "PENDING") {
      setProgressPercentage(100);
      return;
    }

    const updateProgress = () => {
      const startTime = new Date(candleStartTime);
      startTime.setSeconds(0, 0);
      const endTime = new Date(candleEndTime).getTime();
      const now = new Date().getTime();
      const totalDuration = endTime - startTime.getTime();
      const elapsedTime = now - startTime.getTime();
      const progress = Math.max(
        ((totalDuration - elapsedTime) / totalDuration) * 100,
        0
      );
      setProgressPercentage(progress);
    };

    updateProgress();

    const interval = setInterval(updateProgress, 1000);

    return () => clearInterval(interval);
  }, [candleStartTime, candleEndTime, status]);

  const progressBar = {
    position: "absolute",
    right: 0,
    width: `${progressPercentage}%`,
    height: "4px",
    backgroundColor: status === "PENDING" ? "#D89611" : "#00DB97",
    transition: "width 1s linear",
    borderRadius: "16px",
  };

  const progressBarBackground = {
    position: "absolute",
    right: 0,
    width: "100%",
    height: "4px",
    backgroundColor: "#121f27",
    borderRadius: "16px",
  };

  return (
    <Box sx={{ width: "100%", position: "relative", marginTop: "8px" }}>
      <Box sx={progressBarBackground} />
      <Box sx={progressBar} />
    </Box>
  );
};
