import SvgIcon from "@mui/material/SvgIcon";

export default function CgTimelapse() {
  return (
    <SvgIcon viewBox="0 0 24 24" sx={{ width: "18px", height: "18px" }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7845 18.569C9.93203 18.569 8.25498 17.8182 7.04102 16.6042L11.7845 11.8607V5.15234C15.4894 5.15234 18.4928 8.15577 18.4928 11.8607C18.4928 15.5656 15.4894 18.569 11.7845 18.569Z"
          fill="#01DB97"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3275 11.862C22.3275 17.684 17.6078 22.4036 11.7858 22.4036C5.96381 22.4036 1.24414 17.684 1.24414 11.862C1.24414 6.03998 5.96381 1.32031 11.7858 1.32031C17.6078 1.32031 22.3275 6.03998 22.3275 11.862ZM20.4108 11.862C20.4108 16.6255 16.5493 20.487 11.7858 20.487C7.02235 20.487 3.16081 16.6255 3.16081 11.862C3.16081 7.09853 7.02235 3.23698 11.7858 3.23698C16.5493 3.23698 20.4108 7.09853 20.4108 11.862Z"
          fill="#01DB97"
        />
      </svg>
    </SvgIcon>
  );
}
