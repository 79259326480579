import { Box, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import CountdonwContext from "src/contexts/CountdownContext";
import useApiData from "src/hooks/useApiData";

function Countdown() {
  const { t } = useTranslation("dashboard");
  const { count, count1m, timeFormat, timeFormat1m } =
    useContext(CountdonwContext);

  const { operationMode } = useApiData();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isNewOptionsTab = operationMode === "OPTION";

  const countValue = isNewOptionsTab ? count : count1m;

  if (
    (timeFormat === "" && operationMode === "OPTION") ||
    timeFormat1m === ""
  ) {
    return (
      <Box pl={0.4} pt={0.8} pb={0.4}>
        <Skeleton variant="text" width={80} height={16} />
        {operationMode === "OPTION" && isMobile && <Typography height={16} />}
      </Box>
    );
  }

  return (
    <Box pl={0.4} pt={0.8} pb={0.4}>
      <Typography
        fontWeight={600}
        color={count < 6 ? "#FF5382" : "#00DB97"}
        variant="body2"
      >
        {operationMode === "OPTION" ? timeFormat : timeFormat1m}{" "}
        {t("segundos").toLocaleLowerCase()}
      </Typography>
      {operationMode === "OPTION" && isMobile && <Typography height={16} />}
    </Box>
  );
}

export default Countdown;
