import { ThemeProvider, useMediaQuery } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt-BR";
import type { FC } from "react";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useIdleTimer } from "react-idle-timer";
import { useLocation, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import GlobalStyles from "./components/GlobalStyles";
import IdleModal from "./components/modals/IdleTimerModal";
import RTL from "./components/RTL";
import CookiesWarning from "./components/shared/CookiesWarning";
import SplashScreen from "./components/SplashScreen";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import "./i18n";
import routes from "./routes";
import { createTheme } from "./theme";
import { clearDepositIntervalId } from "./utils/interval";

const locationDate = {
  br: pt,
  en: en,
  es: es,
};

ReactGA.initialize("G-P0EWS7HFEW");

const App: FC = () => {
  const content = useRoutes(routes);
  const location = useLocation();
  const { settings } = useSettings();
  const auth = useAuth();

  const [openIdleModal, setOpenIdleModal] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useScrollReset();

  useEffect(() => {
    const defaultSymbol = localStorage.getItem("defaultSymbol");
    if (!defaultSymbol || defaultSymbol === "undefined") {
      localStorage.setItem("defaultSymbol", "IDXUSDT");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    clearDepositIntervalId();
  }, [location]);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // document.addEventListener(
  //   "touchmove",
  //   function (event) {
  //     // @ts-ignore
  //     if (event.scale !== 1) {
  //       event.preventDefault();
  //     }
  //   },
  //   { passive: false }
  // );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setIsFirstLoad(false);
      } else {
        if (
          !isFirstLoad &&
          isMobile &&
          window.location.pathname === "/traderoom"
        ) {
          window.location.reload();
        }
        setIsFirstLoad(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isFirstLoad, isMobile]);

  useIdleTimer({
    timeout: 8000 * 60,
    onIdle: () => {
      if (window.location.pathname === "/traderoom") {
        setOpenIdleModal(true);
      }
    },
    onActive: () => {
      if (openIdleModal) {
        window.location.reload();
      }
    },
    debounce: 500,
    crossTab: true,
    syncTimers: 5000,
  });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locationDate[settings.language]}
    >
      <ThemeProvider theme={theme}>
        <RTL direction={settings.direction}>
          <ToastContainer limit={3} />
          <CookiesWarning />
          <GlobalStyles />
          {auth.isInitialized ? content : <SplashScreen />}
          <IdleModal openModal={openIdleModal} />
        </RTL>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
