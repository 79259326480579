import SvgIcon from "@mui/material/SvgIcon";

export default function OptionBull() {
  return (
    <SvgIcon viewBox="0 0 70 31" sx={{ width: "46px", height: "26px" }}>
      <svg
        width="80"
        height="31"
        viewBox="0 0 80 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(10, 0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.6288 29.6242C48.4609 29.6242 54.8045 23.2965 54.8045 15.4977C54.8045 7.69883 48.4609 1.37109 40.6288 1.37109C32.7967 1.37109 26.4531 7.69883 26.4531 15.4977C26.4531 23.2965 32.7967 29.6242 40.6288 29.6242ZM40.6288 30.6242C49.0101 30.6242 55.8045 23.8518 55.8045 15.4977C55.8045 7.14349 49.0101 0.371094 40.6288 0.371094C32.2475 0.371094 25.4531 7.14349 25.4531 15.4977C25.4531 23.8518 32.2475 30.6242 40.6288 30.6242Z"
            fill="#212121"
          />
          <path
            d="M48.4989 11.8747C48.4184 11.6809 48.2643 11.5268 48.0705 11.4463C47.9751 11.4056 47.8726 11.3841 47.769 11.3828H43.8019C43.5915 11.3828 43.3896 11.4664 43.2409 11.6152C43.0921 11.764 43.0085 11.9658 43.0085 12.1762C43.0085 12.3867 43.0921 12.5885 43.2409 12.7373C43.3896 12.8861 43.5915 12.9696 43.8019 12.9696H45.8568L41.4216 17.4048L38.8113 14.7866C38.7375 14.7122 38.6498 14.6532 38.5531 14.6129C38.4564 14.5726 38.3527 14.5519 38.248 14.5519C38.1432 14.5519 38.0395 14.5726 37.9428 14.6129C37.8462 14.6532 37.7584 14.7122 37.6846 14.7866L32.9241 19.5471C32.8498 19.6208 32.7908 19.7086 32.7505 19.8053C32.7102 19.9019 32.6895 20.0057 32.6895 20.1104C32.6895 20.2151 32.7102 20.3188 32.7505 20.4155C32.7908 20.5122 32.8498 20.6 32.9241 20.6737C32.9979 20.7481 33.0857 20.8071 33.1823 20.8474C33.279 20.8877 33.3827 20.9084 33.4875 20.9084C33.5922 20.9084 33.6959 20.8877 33.7926 20.8474C33.8893 20.8071 33.977 20.7481 34.0508 20.6737L38.248 16.4686L40.8583 19.0869C40.9321 19.1613 41.0198 19.2203 41.1165 19.2606C41.2132 19.3008 41.3169 19.3216 41.4216 19.3216C41.5264 19.3216 41.6301 19.3008 41.7268 19.2606C41.8234 19.2203 41.9112 19.1613 41.985 19.0869L46.9755 14.0884V16.1433C46.9755 16.3537 47.0591 16.5555 47.2079 16.7043C47.3567 16.8531 47.5585 16.9367 47.769 16.9367C47.9794 16.9367 48.1812 16.8531 48.33 16.7043C48.4788 16.5555 48.5624 16.3537 48.5624 16.1433V12.1762C48.5611 12.0725 48.5396 11.9701 48.4989 11.8747Z"
            fill="white"
          />
        </g>
        <circle cx="15.9805" cy="15.498" r="15.127" fill="#10171C" />
        <g clip-path="url(#clip0_2833_27378)">
          <path
            d="M12.6693 6.62305H10.4616V8.67303H8.25391V20.9729H10.4616V23.0229H12.6693V20.9729H14.8769V8.67303H12.6693V6.62305ZM23.7076 10.723H21.5V6.62305H19.2923V10.723H17.0846V17.898H19.2923V23.0229H21.5V17.898H23.7076V10.723Z"
            fill="#01DB97"
          />
        </g>
        <defs>
          <clipPath id="clip0_2833_27378">
            <rect
              width="17.7434"
              height="16.476"
              fill="white"
              transform="translate(7.10938 6.62305)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
