import PropTypes from "prop-types";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getBrokerNowTime } from "src/components/TVChartContainer/datafeed";
import { getLastBar } from "src/components/TVChartContainer/streaming";
import useApiData from "src/hooks/useApiData";
import TradingViewContext from "./TradingViewContext";

interface CountdonwProviderProps {
  children: ReactNode;
}

interface CountdonwContextValue {
  selectedTimeFrame: any;
  setSelectedTimeFrame: (timeFrame: any) => void;
  timeFormat: string;
  timeFormat1m: string;
  count: number;
  count1m: number;
  countValue: number;
}

const CountdonwContext = createContext<CountdonwContextValue>({
  selectedTimeFrame: null,
  setSelectedTimeFrame: () => null,
  timeFormat: "",
  timeFormat1m: "",
  count: 0,
  count1m: 0,
  countValue: 0,
});

export const useCountdownContext = () => {
  const context = useContext(CountdonwContext);
  if (context === undefined) {
    throw new Error(
      "useCountdownContext must be used within a CountdownProvider"
    );
  }
  return context;
};

export const CountdonwProvider: FC<CountdonwProviderProps> = (props) => {
  const { children } = props;

  const { selectedSymbol } = useContext(TradingViewContext);
  const { operationMode } = useApiData();

  const isNewOptionsTab = operationMode === "OPTION";

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(null);
  const [count, setCount] = useState(0);
  const [timeFormat, setTimeFormat] = useState("");
  const [timeFormat1m, setTimeFormat1m] = useState("");
  const [count1m, setCount1m] = useState(0);

  const lastBar = getLastBar(selectedSymbol, "CountdonwContext");

  function refreshClock() {
    if (!selectedTimeFrame) {
      return;
    }

    const displayFormatTime = new Date();
    const dataBroker = new Date(getBrokerNowTime());
    let resultTimeCalcululate = 0;
    let timer = 0;
    let minutes = dataBroker.getMinutes();
    let seconds = dataBroker.getSeconds();
    switch (selectedTimeFrame.value) {
      case "M5":
      case "M15":
        do {
          timer = timer + selectedTimeFrame?.valueChart;
        } while (!(minutes < timer));
        resultTimeCalcululate = (timer - minutes) * 60 - seconds;
        break;
      default:
        resultTimeCalcululate = selectedTimeFrame?.valueChart * 60 - seconds;
        break;
    }

    displayFormatTime.setHours(0);
    displayFormatTime.setMinutes(0);
    displayFormatTime.setSeconds(resultTimeCalcululate);
    displayFormatTime.setMilliseconds(0);
    setCount(resultTimeCalcululate);

    const time = `${resultTimeCalcululate < 600 ? "0" : ""}${Math.floor(
      resultTimeCalcululate / 60
    )}:${resultTimeCalcululate % 60 < 10 ? "0" : ""}${
      resultTimeCalcululate % 60
    }`;
    setTimeFormat(time);

    const adjustedSeconds = (60 - ((seconds + 30) % 60)) % 60;
    const time1m = `${adjustedSeconds < 10 ? "0" : ""}${adjustedSeconds}`;
    setTimeFormat1m(time1m);

    const count1mValue = adjustedSeconds;
    setCount1m(count1mValue);
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [lastBar?.time, selectedTimeFrame]);

  const countValue = isNewOptionsTab ? count : count1m;

  return (
    <CountdonwContext.Provider
      value={{
        timeFormat,
        timeFormat1m,
        count,
        count1m,
        countValue,
        selectedTimeFrame,
        setSelectedTimeFrame,
      }}
    >
      {children}
    </CountdonwContext.Provider>
  );
};

CountdonwProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CountdonwContext;
