import SvgIcon from "@mui/material/SvgIcon";

export default function RetractionSell() {
  return (
    <SvgIcon viewBox="0 0 70 31" sx={{ width: "46px", height: "26px" }}>
      <svg
        width="80"
        height="31"
        viewBox="0 0 80 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(10, 0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.4257 29.9875C48.2578 29.9875 54.6014 23.6598 54.6014 15.8609C54.6014 8.06211 48.2578 1.73438 40.4257 1.73438C32.5936 1.73438 26.25 8.06211 26.25 15.8609C26.25 23.6598 32.5936 29.9875 40.4257 29.9875ZM40.4257 30.9875C48.807 30.9875 55.6014 24.2151 55.6014 15.8609C55.6014 7.50677 48.807 0.734375 40.4257 0.734375C32.0444 0.734375 25.25 7.50677 25.25 15.8609C25.25 24.2151 32.0444 30.9875 40.4257 30.9875Z"
            fill="#212121"
          />
          <path
            d="M48.2958 20.7776C48.2153 20.9715 48.0612 21.1255 47.8673 21.2061C47.772 21.2467 47.6695 21.2683 47.5658 21.2695H43.5988C43.3883 21.2695 43.1865 21.1859 43.0377 21.0371C42.8889 20.8884 42.8053 20.6865 42.8053 20.4761C42.8053 20.2657 42.8889 20.0639 43.0377 19.9151C43.1865 19.7663 43.3883 19.6827 43.5988 19.6827H45.6537L41.2185 15.2475L38.6082 17.8658C38.5344 17.9401 38.4467 17.9992 38.35 18.0394C38.2533 18.0797 38.1496 18.1005 38.0448 18.1005C37.9401 18.1005 37.8364 18.0797 37.7397 18.0394C37.643 17.9992 37.5553 17.9401 37.4815 17.8658L32.721 13.1053C32.6467 13.0315 32.5876 12.9438 32.5473 12.8471C32.5071 12.7504 32.4863 12.6467 32.4863 12.542C32.4863 12.4372 32.5071 12.3335 32.5473 12.2368C32.5876 12.1401 32.6467 12.0524 32.721 11.9786C32.7948 11.9043 32.8825 11.8452 32.9792 11.805C33.0759 11.7647 33.1796 11.7439 33.2843 11.7439C33.3891 11.7439 33.4928 11.7647 33.5895 11.805C33.6862 11.8452 33.7739 11.9043 33.8477 11.9786L38.0448 16.1837L40.6552 13.5655C40.7289 13.4911 40.8167 13.4321 40.9134 13.3918C41.0101 13.3515 41.1138 13.3308 41.2185 13.3308C41.3232 13.3308 41.427 13.3515 41.5236 13.3918C41.6203 13.4321 41.7081 13.4911 41.7818 13.5655L46.7724 18.564V16.509C46.7724 16.2986 46.856 16.0968 47.0048 15.948C47.1536 15.7992 47.3554 15.7156 47.5658 15.7156C47.7763 15.7156 47.9781 15.7992 48.1269 15.948C48.2757 16.0968 48.3593 16.2986 48.3593 16.509V20.4761C48.358 20.5798 48.3364 20.6822 48.2958 20.7776Z"
            fill="white"
          />
        </g>
        <circle cx="15.7773" cy="15.8613" r="15.127" fill="#100A14" />
        <path
          d="M15.7767 22.569C13.9242 22.569 12.2472 21.8182 11.0332 20.6042L15.7767 15.8607V9.15234C19.4816 9.15234 22.485 12.1558 22.485 15.8607C22.485 19.5656 19.4816 22.569 15.7767 22.569Z"
          fill="#FF025C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3197 15.862C26.3197 21.684 21.6 26.4036 15.778 26.4036C9.956 26.4036 5.23633 21.684 5.23633 15.862C5.23633 10.04 9.956 5.32031 15.778 5.32031C21.6 5.32031 26.3197 10.04 26.3197 15.862ZM24.403 15.862C24.403 20.6255 20.5415 24.487 15.778 24.487C11.0145 24.487 7.15299 20.6255 7.15299 15.862C7.15299 11.0985 11.0145 7.23698 15.778 7.23698C20.5415 7.23698 24.403 11.0985 24.403 15.862Z"
          fill="#FF025C"
        />
      </svg>
    </SvgIcon>
  );
}
