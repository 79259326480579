export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const CANDLE_BUCKETS_DEFAULT = [
  { label: "1 min", value: "M1", valueChart: 1 },
];

export const ENVIRONMENT = [
  {
    label: "REAL",
    value: "REAL",
  },
  {
    label: "DEMO",
    value: "TEST",
  },
];

export const symbols = [
  {
    symbol: "BTC/USDT",
    name: "BTCUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BTCUSDT.png",
  },
  {
    symbol: "EUR/USD",
    name: "EURUSD",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/EURUSD.png",
  },
  {
    symbol: "ETH/USDT",
    name: "ETHUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ETHUSDT.png",
  },
  {
    symbol: "IDX/USDT",
    name: "IDXUSDT",
    type: "crypto",
    image: "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/IDX.png",
  },
  {
    symbol: "MEMX/USDT",
    name: "MEMXUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/MEMXUSDT.png",
  },
  {
    symbol: "GBP/USD",
    name: "GBPUSD",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/GBPUSD.png",
  },
  {
    symbol: "USD/CHF",
    name: "USDCHF",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDCHF.png",
  },
  {
    symbol: "USD/JPY",
    name: "USDJPY",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDJPY.png",
  },
  {
    symbol: "BNB/USDT",
    name: "BNBUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BNBUSDT.png",
  },
  {
    symbol: "SOL/USDT",
    name: "SOLUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/SOLUSDT.png",
  },
  {
    symbol: "XRP/USDT",
    name: "XRPUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/XRPUSDT.png",
  },
  {
    symbol: "ADA/USDT",
    name: "ADAUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ADAUSDT.png",
  },
  {
    symbol: "TRUMP/USDT",
    name: "TRUMPUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/TRUMPUSDT.png",
  },
];

export const SYMBOL_IMAGES = {
  BTCUSDT: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BTCUSDT.png",
  },
  ETHUSDT: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ETHUSDT.png",
  },
  SOLUSDT: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/SOLUSDT.png",
  },
  XRPUSDT: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/XRPUSDT.png",
  },
  ADAUSDT: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ADAUSDT.png",
  },
  EURUSD: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/EURUSD.png",
  },
  GBPUSD: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/GBPUSD.png",
  },
  USDJPY: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDJPY.png",
  },
  USDCHF: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDCHF.png",
  },
  IDXUSDT: {
    image: "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/IDX.png",
  },
  MEMXUSDT: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/MEMXUSDT.png",
  },
  TRUMPUSDT: {
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/TRUMPUSDT.png",
  },
};

export const symbolsInfo = [
  {
    description: "BTC/USDT",
    exchange: "Binance",
    full_name: "Binance:BTC/USDT",
    symbol: "BTC/USDT",
    ticker: "BTCUSDT",
    name: "BTCUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BTCUSDT.png",
  },
  {
    description: "EUR/USD",
    exchange: "Binance",
    full_name: "Binance:EUR/USD",
    symbol: "EUR/USD",
    ticker: "EURUSD",
    name: "EURUSD",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/EURUSD.png",
  },
  {
    description: "ETH/USDT",
    exchange: "Binance",
    full_name: "Binance:ETH/USDT",
    symbol: "ETH/USDT",
    ticker: "ETHUSDT",
    name: "ETHUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ETHUSDT.png",
  },
  {
    description: "GBP/USD",
    exchange: "FX",
    full_name: "FX:GBP/USD",
    symbol: "GBP/USD",
    ticker: "GBPUSD",
    name: "GBPUSD",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/GBPUSD.png",
  },
  {
    description: "USD/CHF",
    exchange: "FX",
    full_name: "FX:USD/CHF",
    symbol: "USD/CHF",
    ticker: "USDCHF",
    name: "USDCHF",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDCHF.png",
  },
  {
    description: "USD/JPY",
    exchange: "FX",
    full_name: "FX:USD/JPY",
    symbol: "USD/JPY",
    ticker: "USDJPY",
    name: "USDJPY",
    type: "forex",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDJPY.png",
  },
  {
    description: "BNB/USDT",
    exchange: "Binance",
    full_name: "Binance:BNB/USDT",
    symbol: "BNB/USDT",
    ticker: "BNBUSDT",
    name: "BNBUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BNBUSDT.png",
  },
  {
    description: "SOL/USDT",
    exchange: "Binance",
    full_name: "Binance:SOL/USDT",
    symbol: "SOL/USDT",
    ticker: "SOLUSDT",
    name: "SOLUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/SOLUSDT.png",
  },
  {
    description: "XRP/USDT",
    exchange: "Binance",
    full_name: "Binance:XRP/USDT",
    symbol: "XRP/USDT",
    ticker: "XRPUSDT",
    name: "XRPUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/XRPUSDT.png",
  },
  {
    description: "ADA/USDT",
    exchange: "Binance",
    full_name: "Binance:ADA/USDT",
    symbol: "ADA/USDT",
    ticker: "ADAUSDT",
    name: "ADAUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ADAUSDT.png",
  },
  {
    description: "IDX/USDT",
    exchange: "Binance",
    full_name: "Binance:IDX/USDT",
    symbol: "IDX/USDT",
    ticker: "IDXUSDT",
    name: "IDXUSDT",
    type: "crypto",
    image: "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/IDX.png",
  },
  {
    description: "MEMX/USDT",
    exchange: "Binance",
    full_name: "Binance:MEMX/USDT",
    symbol: "MEMX/USDT",
    ticker: "MEMXUSDT",
    name: "MEMXUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/MEMXUSDT.png",
  },
  {
    description: "TRUMP/USDT",
    exchange: "Binance",
    full_name: "Binance:TRUMP/USDT",
    symbol: "TRUMP/USDT",
    ticker: "TRUMPUSDT",
    name: "TRUMPUSDT",
    type: "crypto",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/TRUMPUSDT.png",
  },
];
